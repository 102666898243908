import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import TextButton from '../../components/Controls/TextButton';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '../../components/Controls/Switch';
import { Flex } from '../../components/Container/Flex';
import { compose } from 'redux';
import {
  setAlarmSoundEnabled,
  setAlertSoundEnabled,
  setAssistanceRequestSoundEnabled,
} from '../../redux/actions/alarm.actions';
import { createStructuredSelector } from 'reselect';
import {
  selectAlarmSoundEnabled,
  selectAlertSoundEnabled,
  selectAssistanceRequestSoundEnabled,
} from '../../redux/selectors/alarm.selector';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import {setSoundSettingsOpen, toggleDisconnectedSound} from '../../redux/actions/app.actions';
import {
	selectCurrentUser,
	selectDisconnectedSoundEnabled,
	selectSoundSettingsOpen
} from '../../redux/selectors/app.selectors';


export function mapDispatchToProps(dispatch) {
	return {
		close: () => dispatch(setSoundSettingsOpen(false)),
		setAlarms: e => dispatch(setAlarmSoundEnabled(e)),
		setAlerts: e => dispatch(setAlertSoundEnabled(e)),
		setAssistanceRequests: e => dispatch(setAssistanceRequestSoundEnabled(e)),
		setDisconnected: e => dispatch(toggleDisconnectedSound())
	};
}

const mapStateToProps = createStructuredSelector({
	isOpen: selectSoundSettingsOpen(),
	alarms: selectAlarmSoundEnabled(),
	alerts: selectAlertSoundEnabled(),
	assistanceRequests: selectAssistanceRequestSoundEnabled(),
	user: selectCurrentUser(),
	disconnectedEnabled: selectDisconnectedSoundEnabled()
});

export default compose(
	connect(mapStateToProps, mapDispatchToProps)
)(({user, isOpen, close, alarms, alerts, assistanceRequests, setAlarms, setAlerts, setAssistanceRequests, disconnectedEnabled, setDisconnected}) => {
	const [t] = useTranslation();
	const {enqueueSnackbar} = useSnackbar();

	return (
		<Dialog
			open={isOpen} onClose={close}>
			<DialogTitle>{t('soundSettings')}</DialogTitle>
			<DialogContent>
				<Flex col>
					<FormControlLabel
						value={!!alarms}
						control={(
							<Switch
								checked={!!alarms || user.isARC}
								disabled={user.isARC}
								onChange={e => {
									enqueueSnackbar(t('preferenceSaved'));
									setAlarms(e);
								}}
							/>
						)}
						label={t('alarms')}
						labelPlacement="end"
					/>
					{!user.isARC && (
						<FormControlLabel
							value={!!alerts}
							control={(
								<Switch
									checked={!!alerts}
									onChange={e => {
										enqueueSnackbar(t('preferenceSaved'));
										setAlerts(e);
									}}
								/>
							)}
							label={t('alerts')}
							labelPlacement="end"
						/>
					)}
					{!user.isARC && (
						<FormControlLabel
							value={!!assistanceRequests}
							control={(
								<Switch
									checked={!!assistanceRequests}
									onChange={e => {
										enqueueSnackbar(t('preferenceSaved'));
										setAssistanceRequests(e);
									}}
								/>
							)}
							label={t('assistanceRequests')}
							labelPlacement="end"
						/>
					)}
						<FormControlLabel
							value={!!disconnectedEnabled}
							control={(
								<Switch
									checked={!!disconnectedEnabled}
									onChange={e => {
										enqueueSnackbar(t('preferenceSaved'));
										setDisconnected(e);
									}}
								/>
							)}
							label={t('connectionLost')}
							labelPlacement="end"
						/>
				</Flex>
			</DialogContent>
			<DialogActions>
				<TextButton onClick={() => close()}>
					{t('close')}
				</TextButton>
			</DialogActions>
		</Dialog>
	);

});