import Immutable from 'immutable';
import dayjs from 'dayjs';
import { colorForTrigger, eventToTrigger, triggerIcons, triggerLabels } from './Scenario';
import { QuestionIcon } from '../icons';

const EventModel = Immutable.Record({
	id: 0,
	date: 0,
	uiState: true,
	title: '',
	message: '',
	payload: {},
	isTest: false,
	event: '',
	disabled: false,
	reason: '',


	deviceId: 0,
});

export default class Event extends EventModel {
	id: number;
	date: number;
	uiState: boolean;
	title: string;
	message: string;
	payload: any;
	reason: string;
	isTest: boolean;
	event: string;
	disabled: boolean;

	get key() {
		return `event_${this.id}`;
	}

	get unixDate() {
		return this.get('date');
	}
	get color() {
		return colorForTrigger(this.trigger);
	}
	get label() {
		return triggerLabels[this.trigger] || "unknownEvent";
	}
	get trigger() {
		return eventToTrigger[this.type];
	}
	get icon() {
		return triggerIcons[this.trigger] || QuestionIcon;
	}

	get dateObj() : dayjs {
		return dayjs.unix(this.get('date'));
	}

	get type() {
		return this.get('event');
	}

	get isOpen() : boolean {
		return +this.get('uiState') === 0;
	}

	get stateName() : string {
		return this.isOpen ? 'open' : 'closed';
	}
}
