import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import React from 'react';
import {createStructuredSelector} from 'reselect';

import reducer from './reducer';
import injectSaga from '../../utils/injectSaga';
import saga from './saga';
import {connect} from 'react-redux';
import {compose} from 'redux';
import injectReducer from '../../utils/injectReducer';
import {showAlert} from './actions';
import {ALERT_DIALOG} from './constants';
import {selectAlert} from './selectors';
import {Secondary} from '../../utils/style/texts';
import {useTranslation} from 'react-i18next';
import SlideUpTransition from '../../components/SlideUpTransition';
import TextField from '../../components/Controls/TextField';
import {DialogTitle} from '@mui/material';
import TextButton from '../../components/Controls/TextButton';
import Dialog from '../../components/Dialog';

const AlertDialog = props => {
    const [t] = useTranslation();
    const [inputValue, setInputValue] = React.useState(undefined);
    const [isOpen, setOpen] = React.useState(null);
    
    const finalize = () => {
        setOpen(false);
        setTimeout(() => {
            props.onCloseAlert();
            setOpen(null);
        }, 300);
    }

    const opts = props.alert.opts || {};
    const {text, title} = props.alert;

    let disableSaveFunc = () => false;
    let inputOpts = {};
    if (opts.input) {
        let {disableSave, ...other} = opts.input;
        disableSaveFunc = disableSave || disableSaveFunc;
        inputOpts = other;
    }

    return (
        <Dialog
            open={!!text && isOpen === null}
            onError={{
                catch: reset => {
                    if (opts.onCancel) {
                        opts.onCancel();
                        reset();
                    }
                }
            }}
            id={opts.id}
            TransitionComponent={SlideUpTransition}
            TransitionProps={{
                onExited: () => setInputValue(undefined)
            }}
            onClose={(e, reason) => {
                if (opts.disableBackdropClick && reason === 'backdropClick') return false;
                if (opts.onCancel) opts.onCancel();
                if (opts.onClose) opts.onClose(); 
                else finalize();
            }}>
            {title && (
                <DialogTitle>{title}</DialogTitle>
            )}
            <DialogContent style={{minWidth: 300}}>
                <Secondary>
                    {text}
                </Secondary>
                {opts.input && (
                    <TextField
                        {...inputOpts}
                        value={inputValue}
                        onChange={setInputValue}
                    />
                )}
            </DialogContent>
            <DialogActions>
                {(!!opts.onCancel || !!opts.cancelText) && (
                    <TextButton onClick={() => {
                        if (opts.onCancel) opts.onCancel();
                        finalize();
                    }}>{opts.cancelText || t('cancel')}</TextButton>
                )}
                {(!!opts.onReject || !!opts.rejectText) && (
                    <TextButton onClick={() => {
                        if (opts.onReject) opts.onReject();
                        finalize();
                    }}>{opts.rejectText || t('no')}</TextButton>
                )}
                <TextButton
                    disabled={disableSaveFunc(inputValue)}
                    onClick={() => {
                        if (opts.onConfirm) opts.onConfirm(inputValue || undefined);
                        finalize();
                    }} color="primary"
                >
                    {opts.closeText || opts.confirmText || t('ok')}
                </TextButton>
            </DialogActions>
        </Dialog>
    );
}


function mapDispatchToProps(dispatch) {
    return {
        onCloseAlert: () => dispatch(showAlert())
    };
}

const mapStateToProps = createStructuredSelector({
    alert: selectAlert()
});

const withReducer = injectReducer({key: ALERT_DIALOG, reducer});
const withSaga = injectSaga({key: ALERT_DIALOG, saga});

export default compose(
    withReducer,
    withSaga,
    connect(mapStateToProps, mapDispatchToProps)
)(AlertDialog);