import { put, select, takeLatest } from 'redux-saga/effects';
import { WS_DATA_SITES } from '../constants/site.constants';
import { selectSites } from '../selectors/site.selector';
import { setSelectedSite } from '../actions/site.actions';
import { selectSelectedDevice } from '../selectors/device.selector';
import { SELECT_DEVICE } from '../constants/device.constants';
import Site from '../../utils/models/Site';
import { selectDetailedAlarm } from '../../containers/AlarmPage/selectors';
import { selectSelectedBeacon } from '../selectors/beacon.selector';
import { SELECT_BEACON } from '../constants/beacon.constants';
import { PAN_TO_GEOFENCE } from '../../containers/MapPage/constants';

function* setActiveSite() {
	const device = yield select(selectSelectedDevice());
	const beacon = yield select(selectSelectedBeacon());
	if(!device || !device.position.isValid) {
		if(!beacon) return;
		if(!beacon.siteId) {
			yield put(setSelectedSite(null));
			return;
		}
	}
	const alarm = yield select(selectDetailedAlarm());
	if(alarm) return; //Alarm map is active and will handle this for its own
	const sites = yield select(selectSites());
	let activeSite;
	if(beacon && beacon.siteId) {
		activeSite = sites.find(s => s.id === beacon.siteId);
	} else if(device && device.position.isValid) {
		activeSite = Site.closestToAltitude(sites, device.position.altitude);
	}

	if(activeSite) {
		yield put(setSelectedSite(activeSite));
	} else {
		yield put(setSelectedSite(null));
	}
}

function *setActiveSiteAfterGeofence({geofence}) {
	if(!geofence?.siteId) return;
	const sites = yield select(selectSites());
	const activeSite = sites.find(s => s.id === geofence.siteId);
	yield put(setSelectedSite(activeSite));

}

export const siteSagas = [
	takeLatest(SELECT_DEVICE, setActiveSite),
	takeLatest(SELECT_BEACON, setActiveSite),
	takeLatest(WS_DATA_SITES, setActiveSite),
	takeLatest(PAN_TO_GEOFENCE, setActiveSiteAfterGeofence),
];