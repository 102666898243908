import Immutable from 'immutable';
import dayjs, { Dayjs } from 'dayjs';
import { presets } from '../../components/WeekAvailability';
import {isValidPhoneNumber, sundayFirstToMondayFirst} from '../functions';
import validator from 'validator';

const ContactModel = Immutable.Record({
	id: 0,
	name: '',
	phone: '+',
	email: '',
	function: '',
	remark: '',
	ooo: false,
	oooFrom: dayjs().unix(),
	oooTo: dayjs().add(1, 'days').unix(),
	availability: [],
	accountId: 0
});

export default class Contact extends ContactModel {
	id: number;
	name: string;
	phone: string;
	email: string;
	function: string;
	remark: string;
	ooo: boolean;
	oooFrom: number;
	oooTo: number;
	availability: boolean[][];

	accountId: number;

	get oooFromDate() : Dayjs {
		return dayjs.unix(this.get('oooFrom'));
	}
	get oooToDate() : Dayjs {
		return dayjs.unix(this.get('oooTo'));
	}

	constructor(props) {
		props.availability = props.availability || presets.never;
		super(props);
	}

	isAvailable(date: dayjs) {
		if(!date) date = dayjs();

		if(this.get('ooo')) {
			if(date.unix() > this.get('oooFrom') && date.unix() < this.get('oooTo')) {
				return false;
			}
		}
		const slot = (date.hour()*2) + (date.minute() > 30 ? 1 : 0);
		return this.isAvailableAtSlot(sundayFirstToMondayFirst(date.day()), slot);
	}

	/**
	 * Monday = 0
	 * @param day
	 * @param slot
	 * @returns {*}
	 */
	isAvailableAtSlot(day: number, slot: number) {
		const dayAvailability = this.get('availability')[day];
		return dayAvailability[slot];
	}

	hasOOOAtDate(date: Dayjs) {
		return this.get('ooo') && date.isBetween(this.oooFromDate, this.oooToDate);
	}
	hasOOONowOrSoon() {
		return this.get('ooo') && this.oooFromDate.isBefore(dayjs().add(1, 'week')) && this.oooToDate.isAfter(dayjs());
	}

	isAlwaysAvailable() {
		return this.get('availability').flat().indexOf(false) === -1;
	}

	clone() {
		return this.set('id', this.id);
	}
	
	isValid() {
		if(this.name?.length < 3) return false;
		if(this.hasPhone && !isValidPhoneNumber(this.phone)) return false;
		if(this.email && !validator.isEmail(this.email)) return false;
		return !!this.email || this.hasPhone;
	}
	
	get hasPhone() {
		return this.phone?.length >= 3;
	}

	static create() {
		const now = dayjs();
		return new Contact({
			availability: presets.always,
			oooFrom: now.unix(),
			oooTo: now.add(1, 'day').unix()
		});
	}

	static mergeAvailability(contacts: Contact[]) {
		const availability = presets.never;
		availability.forEach((dayAvailability, day) => {
			dayAvailability.forEach((available, slot) => {
				availability[day][slot] = contacts.filter(g => g && g.isAvailableAtSlot(day, slot)).length;
			});
		});
		return availability;
	}
}
