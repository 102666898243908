import { Colors } from '../../utils/style/colors';
import { moduleIds } from '../../utils/models/Module';
import {getStore} from "../../configureStore";
import {APP} from "../../redux/constants/app.constants";

const defaultTile = {
	disabled: () => false,
	wsTopics: ['*']
}
export const getTiles = (t) => [
	{
		...defaultTile,
		type: 'geofenceAttendance',
		title: t('attendanceOverview'),
		description: t('geofenceAttendance.tile.description'),
		icon: 'GeofenceIcon',
		color: Colors.geofence,
		config: 'geofenceAttendanceConfig',
		wsTopics: ['geofence', 'geofences', 'device', 'devices', 'geofencePresence', 'site', 'sites']
	},
	{
		...defaultTile,
		type: 'freeText',
		title: t('freeText'),
		description: t('freeText.tile.description'),
		icon: 'TextIcon',
		color: Colors.gray20,
		config: 'freeTextConfig',
		wsTopics: []
	},
	{
		...defaultTile,
		type: 'liveMap',
		title: t('liveMap'),
		description: t('liveMap.tile.description'),
		icon: 'MapIcon',
		color: Colors.lightBlue,
		config: 'liveMapConfig',
		wsTopics: ['geofence', 'geofences', 'device', 'devices', 'geofencePresence', 'site', 'sites', 'team', 'teams']
	},
	{
		...defaultTile,
		type: 'startScenario',
		title: t('startScenario'),
		description: t('startScenario.tile.description'),
		icon: 'ButtonIcon',
		color: Colors.secondary(),
		config: 'selectScenario',
		moduleId: moduleIds.ScenarioTile,
		wsTopics: ['scenario', 'scenarios']
	},
	{
		...defaultTile,
		type: 'startAssistanceRequest',
		title: t('newAssistanceRequest'),
		description: t('startAssistanceRequest.tile.description'),
		icon: 'AssistanceRequestIcon',
		color: Colors.secondary(),
		config: 'assistanceRequestConfig',
		moduleId: moduleIds.AssistanceRequests,
		wsTopics: [
			'device', 'devices',
			'assistanceRequest', 'assistanceRequests',
			'team', 'teams',
			'geofence', 'geofences',
			'quickTexts'
		]
	},
	{
		...defaultTile,
		type: 'pendingAssistanceRequests',
		title: t('pendingAssistanceRequests'),
		description: t('pendingAssistanceRequests.tile.description'),
		icon: 'AssistanceRequestIcon',
		color: Colors.primary(),
		wsTopics: [
			'device', 'devices',
			'assistanceRequest', 'assistanceRequests',
			'team', 'teams',
			'geofence', 'geofences',
			'quickTexts'
		]
	},
	{
		...defaultTile,
		type: 'openAlarms',
		title: t('openAlarms'),
		description: t('openAlarms.tile.description'),
		icon: 'AlarmIcon',
		color: Colors.primary(),
		wsTopics: ['alarm', 'alarms']
	},
	{
		...defaultTile,
		type: 'openAlerts',
		title: t('openAlerts'),
		description: t('openAlerts.tile.description'),
		icon: 'AlarmIcon',
		color: Colors.primary(),
		wsTopics: ['event', 'events', 'alarm', 'alarms']
	},
	{
		...defaultTile,
		type: 'daysWithoutAlarm',
		title: t('daysWithoutAlarm'),
		description: t('daysWithoutAlarm.tile.description'),
		icon: 'WithoutAlarmIcon',
		color: Colors.success,
		wsTopics: ['alarms', 'alarm']
	},
	{
		...defaultTile,
		type: 'credits',
		title: t('credits'),
		description: t('credits.tile.description'),
		icon: 'CreditsIcon',
		color: Colors.primary(),
		wsTopics: ['credits']
	},
	{
		...defaultTile,
		type: 'incompleteDevices',
		title: t('incompleteDevices'),
		description: t('incompleteDevices.tile.description'),
		icon: 'IncompleteDeviceIcon',
		color: Colors.warning,
		wsTopics: ['device', 'devices']
	},
	{
		...defaultTile,
		type: 'unsupportedFirmware',
		title: t('updateNeeded'),
		description: t('unsupportedFirmware.tile.description'),
		icon: 'UpgradeIcon',
		color: Colors.warning,
		wsTopics: ['device', 'devices']
	},
	{
		...defaultTile,
		type: 'trial',
		title: t('trial'),
		description: t('trial.tile.description'),
		icon: 'TrialIcon',
		color: Colors.success,
		wsTopics: []
	},
	{
		...defaultTile,
		type: 'iframe',
		disabled: () => !getStore()?.getState()[APP].get('adminMode'),
		title: t('iframe'),
		description: t('iframe.tile.description'),
		icon: 'IframeIcon',
		color: Colors.gray20,
		config: 'iframeConfig',
		wsTopics: []
	}
];