import {
	ACCOUNT_MANAGERS_CHANGED,
	ACCOUNTS_CHANGED,
	BEACONS_CHANGED,
	TEAMS_CHANGED,
	GEOFENCES_CHANGED,
	CHANGE_LAYOUT,
	DEVICE_CHANGED,
	DEVICES_CHANGED,
	DOWNLOAD_ACCOUNT_MANAGER_REPORT, GET_ACCOUNTS,
	GET_ADMIN_DATA,
	GET_DEVICES,
	GET_PROMS,
	PROMS_CHANGED,
	SET_DEVICE_FILTER,
	SET_SELECTED_ACCOUNT, SIM_CARDS_CHANGED,
	SITES_CHANGED, ARCS_CHANGED,
} from './constants';

export const changeLayout = layout => ({ type: CHANGE_LAYOUT, layout });
export const getAdminData = () => ({type: GET_ADMIN_DATA});
export const getDevices = () => ({type: GET_DEVICES});
export const getAccounts = () => ({type: GET_ACCOUNTS});
export const beaconsChanged = beacons => ({type: BEACONS_CHANGED, beacons});
export const teamsChanged = teams => ({type: TEAMS_CHANGED, teams});
export const geofencesChanged = geofences => ({type: GEOFENCES_CHANGED, geofences});
export const simCardsChanged = simCards => ({type: SIM_CARDS_CHANGED, simCards});
export const sitesChanged = sites => ({type: SITES_CHANGED, sites});
export const accountManagersChanged = accountManagers => ({type: ACCOUNT_MANAGERS_CHANGED, accountManagers});
export const accountsChanged = accounts => ({type: ACCOUNTS_CHANGED, accounts});
export const devicesChanged = devices => ({type: DEVICES_CHANGED, devices});
export const deviceChanged = device => ({type: DEVICE_CHANGED, device});
export const getProms = () => ({type: GET_PROMS});
export const promsChanged = proms => ({type: PROMS_CHANGED, proms});
export const arcsChanged = arcs => ({type: ARCS_CHANGED, arcs});
export const setDeviceFilter = filter => ({type: SET_DEVICE_FILTER, filter});


export const downloadAccountManagerReport = (billingCycle, fileName) => ({ type: DOWNLOAD_ACCOUNT_MANAGER_REPORT, billingCycle, fileName});

export const setSelectedAccount = account => ({ type: SET_SELECTED_ACCOUNT, account });