export const deviceTypesInv = {
	TwigProtector: 4,
	TwigProtectorPro: 5,
	TwigProtectorEX: 6,
	TwigProtectorProEX: 9,
	TwigEmbody: 7,
	TwigSOSCard: 10,
	TwigProtectorProOld: 11,
	TeltonikaTMT250Old: 12,
	TeltonikaGH5200: 13,
	Generic: 14,
	App: 15,
	TeltonikaTMT250: 16,
	TwigOne: 17,
	WebUser: 18,
	MoxaE12010: 19,
	TwigNeo: 20,
	TwigOneEx: 21,
	EmeritWatch: 22,
	ESPA: 23,
	TwigRemoteButton: 24,
	TwigSOSCardP5: 25,
	EviewEV04: 26,
	QueclinkGL320M: 27,
	TeltonikaFMC640: 28,
	TwigEasy: 29,
	ESPA2: 30,
	TeltonikaFMC650: 31,
	EmeritConnect: 32,
};

export const deviceTypes = {
	0: () => '',
	[deviceTypesInv.TwigProtector]: () => 'Twig Protector',
	[deviceTypesInv.TwigProtectorPro]: () => 'Twig Protector Pro',
	[deviceTypesInv.TwigProtectorEX]: () => 'Twig Protector EX',
	[deviceTypesInv.TwigProtectorProEX]: () => 'Twig Protector Pro EX',
	[deviceTypesInv.TwigEmbody]: () => 'Twig Embody',
	[deviceTypesInv.TwigSOSCard]: () => 'Twig SOS card P4',
	[deviceTypesInv.TwigProtectorProOld]: () => 'Twig Protector (TUP90EU)',
	[deviceTypesInv.TeltonikaTMT250Old]: () => 'Teltonika TMT250',
	[deviceTypesInv.TeltonikaGH5200]: () => 'Teltonika GH5200',
	[deviceTypesInv.Generic]: (t) => t ? t('deviceType.generic') : 'Generic',
	[deviceTypesInv.App]: (t) => t ? t('deviceType.app') : 'App',
	[deviceTypesInv.TeltonikaTMT250]: () => 'Teltonika TMT250',
	[deviceTypesInv.TwigOne]: () => 'Twig One',
	[deviceTypesInv.WebUser]: () => '',
	[deviceTypesInv.MoxaE12010]: () => 'I/O',
	[deviceTypesInv.TwigNeo]: () => 'Twig Neo',
	[deviceTypesInv.TwigOneEx]: () => 'Twig One Ex',
	[deviceTypesInv.EmeritWatch]: () => 'Emerit Watch',
	[deviceTypesInv.ESPA]: () => 'ESPA 4.4.4',
	[deviceTypesInv.ESPA2]: () => 'ESPA 4.4.4 v2',
	[deviceTypesInv.TwigRemoteButton]: () => 'Twig Remote Button',
	[deviceTypesInv.TwigSOSCardP5]: () => 'Twig SOS card P5',
	[deviceTypesInv.EviewEV04]: () => 'Eview EV-04',
	[deviceTypesInv.QueclinkGL320M]: () => 'Queclink GL320M',
	[deviceTypesInv.TeltonikaFMC640]: () => 'Teltonika FMC640',
	[deviceTypesInv.TeltonikaFMC650]: () => 'Teltonika FMC650',
	[deviceTypesInv.TwigEasy]: () => 'Twig Easy',
	[deviceTypesInv.EmeritConnect]: () => 'Emerit Connect App',
};
export const realDeviceTypes = {
	0: () => '',
	[deviceTypesInv.TwigProtector]: () => 'Twig Protector',
	[deviceTypesInv.TwigProtectorPro]: () => 'Twig Protector Pro',
	[deviceTypesInv.TwigProtectorEX]: () => 'Twig Protector EX',
	[deviceTypesInv.TwigProtectorProEX]: () => 'Twig Protector Pro EX',
	[deviceTypesInv.TwigEmbody]: () => 'Twig Embody',
	[deviceTypesInv.TwigSOSCard]: () => 'Twig SOS card P4',
	[deviceTypesInv.TwigProtectorProOld]: () => 'Twig Protector (TUP90EU)',
	[deviceTypesInv.TeltonikaTMT250Old]: () => 'Teltonika TMT250 < FW55',
	[deviceTypesInv.TeltonikaGH5200]: () => 'Teltonika GH5200',
	[deviceTypesInv.Generic]: (t) => t ? t('deviceType.generic') : 'Generic',
	[deviceTypesInv.App]: (t) => t ? t('deviceType.app') : 'App',
	[deviceTypesInv.TeltonikaTMT250]: () => 'Teltonika TMT250',
	[deviceTypesInv.TwigOne]: () => 'Twig One',
	[deviceTypesInv.WebUser]: () => 'Web',
	[deviceTypesInv.MoxaE12010]: () => 'I/O',
	[deviceTypesInv.TwigNeo]: () => 'Twig Neo',
	[deviceTypesInv.TwigOneEx]: () => 'Twig One Ex',
	[deviceTypesInv.EmeritWatch]: () => 'Emerit Watch',
	[deviceTypesInv.ESPA]: () => 'ESPA 4.4.4',
	[deviceTypesInv.ESPA2]: () => 'ESPA 4.4.4 v2', 
	[deviceTypesInv.TwigRemoteButton]: () => 'Twig Remote Button',
	[deviceTypesInv.TwigSOSCardP5]: () => 'Twig SOS card P5',
	[deviceTypesInv.EviewEV04]: () => 'Eview EV-04',
	[deviceTypesInv.QueclinkGL320M]: () => 'Queclink GL320M',
	[deviceTypesInv.TeltonikaFMC640]: () => 'Teltonika FMC640',
	[deviceTypesInv.TeltonikaFMC650]: () => 'Teltonika FMC650',
	[deviceTypesInv.TwigEasy]: () => 'Twig Easy',
	[deviceTypesInv.EmeritConnect]: () => 'Emerit Connect App',
};
export const manufacturer = {
	'twig': [
		deviceTypesInv.TwigProtector,
		deviceTypesInv.TwigProtectorPro,
		deviceTypesInv.TwigProtectorEX,
		deviceTypesInv.TwigProtectorProEX,
		deviceTypesInv.TwigEmbody,
		deviceTypesInv.TwigSOSCard,
		deviceTypesInv.TwigProtectorProOld,
		deviceTypesInv.TwigOne,
		deviceTypesInv.TwigNeo,
		deviceTypesInv.TwigOneEx,
		deviceTypesInv.TwigRemoteButton,
		deviceTypesInv.TwigSOSCardP5,
		deviceTypesInv.TwigEasy
	],
	'teltonika': [
		deviceTypesInv.TeltonikaTMT250Old,
		deviceTypesInv.TeltonikaGH5200,
		deviceTypesInv.TeltonikaTMT250,
		deviceTypesInv.TeltonikaFMC640,
		deviceTypesInv.TeltonikaFMC650
	],
	'queclink': [
		deviceTypesInv.QueclinkGL320M
	],
	'emerit': [
		deviceTypesInv.EmeritConnect,
		deviceTypesInv.EmeritWatch
	],
	'eview': [
		deviceTypesInv.EviewEV04
	],
	'app': [
		deviceTypesInv.App
	]
};
export const getManufacturer = (deviceTypeId) => {
	return Object.keys(manufacturer)
		.find(m => manufacturer[m].indexOf(deviceTypeId) >= 0) || 'other';
};

export const adminCannotAdd = [
	deviceTypesInv.WebUser,
	deviceTypesInv.App,
	deviceTypesInv.TwigRemoteButton
];
export const deprecated = [
	deviceTypesInv.TeltonikaTMT250Old,
	deviceTypesInv.TwigProtectorProOld,
];