import InputAdornment from '@mui/material/InputAdornment';
import MaterialTextField from '@mui/material/TextField';

import React from 'react';
import styled from 'styled-components';

import {Colors} from '../../utils/style/colors';
import {useTranslation} from 'react-i18next';
import {CloseIcon, SearchIcon, VisibilityIcon, VisibilityOffIcon} from '../../utils/icons';
import LoadingBar from './LoadingBar';
import {IconButton} from '../IconButton';


const TextField = props => {
    const [t] = useTranslation();
    const [showPassword, setShowPassword] = React.useState(false);

    let {
        tReady,
        showSize,
        match,
        startAdornment,
        loading,
        className,
        allowNegative,
        maxLength,
        classes,
        inline,
        onChange,
        disabled,
        editable,
        errorText,
        onSearch,
        onClear,
        onEnter,
        ignoredCharacters,
        InputProps,
        type,
        inputProps,
        helperTextAlign,
        onRef,
        warning,
        ...other
    } = props;

    const helperText = (() => {
        if ((props.error || props.warning) && props.editable !== false) {
            return props.errorText;
        }
        let helperText = '';
        if (props.showSize) helperText += props.value.length;
        if (props.showSize && props.maxLength) helperText += `/${props.maxLength}`;
        if (props.helperText) {
            if (helperText) helperText += ' ';
            helperText = <>{helperText}{props.helperText}</>;
        }
        return <span className={`helperText align-${helperTextAlign}`}>{helperText || ''}</span>;
    })();

    if (!inputProps) inputProps = {};
    if (!InputProps) InputProps = {};

    if (maxLength) {
        inputProps.maxLength = maxLength;
    }
    if (startAdornment) {
        InputProps.startAdornment = (
            <InputAdornment position="start">
                {startAdornment}
            </InputAdornment>
        );
    }
    if (onClear) {
        InputProps.endAdornment = (
            <InputAdornment position="end" style={{opacity: props.value ? 1 : 0, pointerEvents: props.value}}>
                <IconButton disabled={disabled} aria-label={t('doEmpty')} onClick={() => onClear()} size="large">
                    <CloseIcon/>
                </IconButton>
            </InputAdornment>
        );
    }
    if (onSearch) {
        InputProps.endAdornment = (
            <InputAdornment position="end" style={{opacity: props.value ? 1 : 0, pointerEvents: props.value}}>
                <IconButton disabled={disabled} aria-label={t('search')} onClick={() => onSearch()}>
                    <SearchIcon/>
                </IconButton>
            </InputAdornment>
        );
    }
    if (props.type === 'password') {
        InputProps.endAdornment = (
            <InputAdornment position="end">
                <IconButton
                    disabled={disabled}
                    aria-label={t('togglePasswordVisibility')}
                    onClick={() => setShowPassword(!showPassword)}
                    size="large">
                    {showPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                </IconButton>
            </InputAdornment>
        );
        if (showPassword) {
            type = 'text';
        }
    }
    const min = props.min === undefined ? 0 : +props.min;
    // Removed this in favor of negative altitude sites
    // else if(this.props.type === 'number') {
    // 	type = 'text';
    // 	onChange = e => isFinite(e) && this.props.onChange(e)
    // }
    const inputPropObj = {...inputProps, ...InputProps};
    return (
        <Wrapper {...other}
                 inline={inline}
                 className={className}
                 warning={warning}
                 error={(props.error || props.warning) && errorText}
                 helperText={helperText || false}
                 editable={editable !== false}>
            <MaterialTextField
                {...other}
                error={(props.error || props.warning)}
                onChange={e => {
                    if (match) {
                        if (!match.test(e.target.value)) {
                            if (!match.test(e.target.value.trim())) return;
                            e.target.value = e.target.value.trim();
                        }
                    }
                    if (maxLength && e.target.value?.length >= maxLength) return;
                    if (type === 'number' && !allowNegative) {
                        e.target.value = Math.max(min, +e.target.value);
                    }
                    if (type === 'number' && props.max > 0) {
                        e.target.value = Math.min(+props.max, +e.target.value);
                    }
                    if (ignoredCharacters && ignoredCharacters.split("").find(ic => e.target.value.indexOf(ic) >= 0)) {
                        return;
                    }
                    onChange(e.target.value.replace(/\u00A0/, ' '), e);
                }}
                inputRef={r => onRef && onRef(r)}
                disabled={disabled || editable === false}
                onKeyDown={e => {
                    if (e.code === 'Enter' && onEnter) {
                        onEnter(e.target);
                    }
                }}
                value={props.value || ''}
                variant={props.minRows > 1 ? 'outlined' : (props.variant || 'standard')}
                InputProps={inputPropObj}
                helperText={helperText || null}
                type={type}
            />
            {!!loading && (
                <LoadingBar barProps={{style: {height: 2}}}/>
            )}
        </Wrapper>
    );
}

export default TextField;

const Wrapper = styled.div`
	${props => !props.multiline && `
		height: ${props.label ? 50 : 25}px; 
	`};
	${props => props.inline && 'display: inline-block;'};
	transition: height .3s;
	${props => props.fullWidth && `
		width: 100%;
	`};
	${props => !props.editable && `
		input {
			color: ${Colors.gray60};
		}
		*:before, *:after {
			opacity: 0;
		}
	`};
	${props => props.warning && `
	    label {
	        color: ${Colors.warning} !important;
	    }
		.Mui-error:after {
		    border-bottom: 2px solid ${Colors.warning};
		}
		p.Mui-error {
		    color: ${Colors.warning};
		}
	`};
	${props => (props.error || props.warning) && !!props.errorText?.trim() && `
		margin-bottom: 10px;
	`};
	${props => props.multiline && `
		textarea {
			min-height: 40px !important;
		}
	`};
	> * {
		transform: translateY(-5px);
	}
	* {
		border-radius: 0 !important;
	}
	.helperText {
		font-size: 7pt;
    	display: inline-block;
    	width: 100%;
		&.align-right {
			text-align: right;
		}
	}
`;
