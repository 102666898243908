import React from 'react';
import {useTranslation} from 'react-i18next';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {selectDevices, selectDevicesFetched} from '../../redux/selectors/device.selector';
import FormControl from '@mui/material/FormControl';
import Checkbox from '../Controls/Checkbox';
import Alert from '../Alert';
import {forAnyObject, forConcerningObject} from '../../utils/models/Scenario';
import {capitalize} from 'lodash/string';
import {CircularProgress} from '@mui/material';
import {CheckBoxCheckedIcon, CheckBoxUncheckedIcon} from "../../utils/icons";
import MuiAutocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {Flex} from "../Container/Flex";
import {selectCurrentUser} from "../../redux/selectors/app.selectors";
import {Text} from "../../utils/style/texts";
import {StatusDot} from "../../containers/AdminDevicePage/style";

/* eslint jsx-a11y/role-supports-aria-props: 0 */

const inputLabelRef = React.createRef();

const DeviceSelector = props => {
    let {
        user,
        value,
        fetched,
        renderValue,
        showStatus,
        hideOffline,
        disabled,
        hideDisabled,
        isDeviceTrigger,
        isSource,
        scenarioTrigger,
        incompatibleActionText,
        alsoConcerningDevice,
        appsOnly,
        hideUsers,
        devices,
        ...other
    } = props;
    const accountIds = props.accountIds || [user.accountId];  
    
    const onChange = newValue => {
        props.onChange(newValue.filter(v => {
            if(v < 0) return true;
            return accountIds.indexOf(devices.find(d => d.id === v)?.accountId) >= 0;
        }));
    };

    const [t] = useTranslation();
    if (appsOnly) {
        devices = devices.filter(d => d.isApp);
    }
    if (hideUsers) {
        devices = devices.filter(d => !d.isWebUser);
    }

    if (value && value.length) {
        value = value.filter(v => v <= 0 || devices.find(d => +d.id === +v));
    }

    const disableOption = other.disableOption || (() => false);

    if (!Array.isArray(value)) {
        setTimeout(() => onChange([]));
        return null;
    }

    const options = [{
        label: capitalize(isDeviceTrigger ? t('allOtherDevices') : isSource ? t('anyDevice') : t('allDevices')),
        value: forAnyObject
    }];
    if (alsoConcerningDevice) {
        options.push({
            label: t('forConcerningDevice'),
            value: forConcerningObject
        });
    }
    const deviceMap = {};
    devices
        .filter(d => d.connected || !hideOffline)
        .filter(d => accountIds.indexOf(d.accountId) >= 0).forEach(d => {
        options.push({
            label: (
                <Flex row gap={6} align="center">
                    {showStatus && <StatusDot small color={d.color} />}
                    <Text>{accountIds?.length > 1 ? `${d.name} (${d.id})` : d.name}</Text>
                </Flex>
            ),
            value: d.id,
            device: d
        });
        deviceMap[d.id] = d;
    });

    const forAny = value.indexOf(forAnyObject) >= 0;
    const forConcerning = value.indexOf(forConcerningObject) >= 0;
    const defaultLabel = () => {
        if (value.indexOf(forAnyObject) >= 0) return capitalize(isDeviceTrigger ? t('allOtherDevices') : t('allDevices'));
        if (value.indexOf(forConcerningObject) >= 0) return capitalize(t('forConcerningDevice'));
        value = value.filter(v => v <= 0 || devices.find(d => +d.id === +v));
        if (value.length > 1) return t('devices.count', {count: value.length});
        if (value.length === 1) return (devices.find(d => d.id === value[0]) || {}).name;
        return '';
    }

    return (
        <Flex>
            {devices.size ? (
                <FormControl ref={inputLabelRef} fullWidth disabled={!!disabled}>
                    <MuiAutocomplete
                        multiple
                        value={value}
                        renderOption={({dataOptionIndex, ...props}, o) => {
                            const device = deviceMap[o.value];
                            const optionDisabled = disabled || (device?.id > 0 && disableOption(device));
                            const checked =
                                value.indexOf(o.value) >= 0 ||
                                (forAny && o.value !== forConcerningObject && !optionDisabled) ||
                                (forConcerning && o.value === forConcerningObject && !optionDisabled);
                            return (
                                <li {...props}
                                    aria-selected={false}
                                    data-deviceid={o.value}
                                    data-checked={checked ? 'yes' : 'no'}
                                    style={{...props.style, display: hideDisabled && optionDisabled ? 'none' : undefined}}
                                >
                                    <Checkbox
                                        icon={<CheckBoxUncheckedIcon/>}
                                        checkedIcon={<CheckBoxCheckedIcon/>}
                                        disabled={optionDisabled}
                                        color={o.value > 0 ? 'primary' : 'secondary'}
                                        indeterminate={forAny && o.value > 0 && !optionDisabled}
                                        style={{marginRight: 8, pointerEvents: 'none'}}
                                        checked={checked}
                                    />
                                    {o.label}
                                </li>
                            )
                        }}
                        isOptionEqualToValue={(o, v) => value.indexOf(v) >= 0}
                        disabled={!!disabled}
                        disableCloseOnSelect
                        renderTags={() => renderValue ? renderValue() : defaultLabel()}
                        getOptionLabel={o => o.device?.filterString(t) || 'any'} 
                        groupBy={o => accountIds?.length > 1 ? o.device?.accountName : null}
                        onChange={e => {
                            const clickedDeviceId = +e.target.dataset.deviceid;
                            if(isNaN(clickedDeviceId)) return onChange([]);
                            const wasChecked = e.target.dataset.checked === 'yes';
                            if (isSource) {
                                if (wasChecked) {
                                    if (clickedDeviceId === forAnyObject) {
                                        onChange([]);
                                    } else {
                                        if (forAny) {
                                            onChange(options.filter(o => !disableOption(o.device) && o.device && o.value !== clickedDeviceId).map(o => o.value));
                                        } else {
                                            onChange(value.filter(v => v !== clickedDeviceId));
                                        }
                                    }
                                } else {
                                    if (clickedDeviceId === forAnyObject) {
                                        onChange([forAnyObject]);
                                    } else {
                                        onChange([...value, clickedDeviceId]);
                                    }
                                }
                            } else {
                                const possibleConcerningObject = value.filter(v => v === forConcerningObject);
                                if (wasChecked) {
                                    if (clickedDeviceId === forConcerningObject) {
                                        onChange(value.filter(v => v !== forConcerningObject)); 
                                    } else if (clickedDeviceId === forAnyObject) {
                                        onChange(value.filter(v => v === forConcerningObject));
                                    } else {
                                        if (forAny) {
                                            onChange([
                                                ...possibleConcerningObject,
                                                ...options.filter(o => !disableOption(o.device) && o.device && o.value !== clickedDeviceId).map(o => o.value)
                                            ]);
                                        } else {
                                            onChange(value.filter(v => v !== clickedDeviceId));
                                        }
                                    }
                                } else {
                                    if (clickedDeviceId === forAnyObject) {
                                        onChange([forAnyObject, ...possibleConcerningObject]);
                                    } else {
                                        onChange([...value, clickedDeviceId]);
                                    }
                                }
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                fullWidth
                                {...params}
                                label={t('devices')}
                            />
                        )}
                        options={options}
                    />
                </FormControl>
            ) : (
                <>
                    {fetched ? <Alert type="warning" dismissable={false}>{t('noDevices')}</Alert> : (
                        <CircularProgress size={16}/>
                    )}
                </>
            )}
        </Flex>
    );
}

const mapDispatchToProps = () => ({});

const mapStateToProps = createStructuredSelector({
    user: selectCurrentUser(),
    devices: selectDevices(),
    fetched: selectDevicesFetched()
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(DeviceSelector);