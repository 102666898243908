import {scenarioTriggers} from "../../../../utils/models/Scenario";
import Autocomplete from "../../../../components/Controls/Autocomplete";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {createStructuredSelector} from "reselect";
import {selectScenarios} from "../../../../redux/selectors/scenario.selector";
import {connect} from "react-redux";
import {compose} from "redux";


const FollowUpScenarioPicker = props => {
    const [t] = useTranslation();
    const {scenarios, onChange, scenario, config, forTrigger} = props;
    if(!scenario || scenario.trigger === scenarioTriggers.messageNotAcknowledged || scenario.trigger === scenarioTriggers.checkAssistanceRequestResponses) 
        return null;
    
    return (
        <Autocomplete 
            fullWidth
            value={config.followUpScenarioId}
            noOptionsText={t('noCompatibleScenarioYet')}
            onChange={followUpScenarioId => onChange({...config, followUpScenarioId})}
            label={`${t('followUpScenarioWhenNotAcknowledged')} (${t('optional')})`}
            items={scenarios.filter(s => s.trigger === forTrigger).map(s => ({
                value: s.id,
                label: s.name
            }))}
        />
    );
};


function mapDispatchToProps() {
    return {};
}

const mapStateToProps = createStructuredSelector({
    scenarios: selectScenarios()
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(FollowUpScenarioPicker);