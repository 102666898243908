import Immutable from 'immutable';
import { getBoundingBox } from '../map/functions';
import { getCentroid } from '../map';
import {getAreaOfPolygon, isPointWithinRadius, isPointInPolygon} from "geolib";

const defaultLat = 52.083050;
const defaultLng = 5.321159;

export const setGeofenceShape = (object, shape, radius) => {
	if(object.get('shape') === shape) return;
	if(shape === 'polygon') {
		return object.set('points', getBoundingBox(parseFloat(object.get('latitude')), parseFloat(object.get('longitude')), object.get('radius'))).set('shape', shape);
	}
	const lat = object.get('points').map(p => parseFloat(p[0])).reduce((a,b) => a + b, 0) / object.get('points').length;
	const lng = object.get('points').map(p => parseFloat(p[1])).reduce((a,b) => a + b, 0) / object.get('points').length;
	return object.set('latitude', lat).set('longitude', lng).set('radius', radius || 500).set('shape', shape);
};
export const getCenterArray = object => {
	if(object.get('shape') === 'circle') {
		return [object.get('latitude'), object.get('longitude')];
	} else {
		return getCentroid(object.points);
	}
};

const GeofenceModel = Immutable.Record({
	id: 0,
	accountId: 0,
	name: '',
	latitude: 0,
	longitude: 0,
	altitude: 0,
	radius: 0,
	shape: 'circle',
	points: [],
	hidden: false,
	locked: false,
	siteId: null,
	accountName: '',
});

export default class Geofence extends GeofenceModel {
	id: number;
	accountId: number;
	name: string;
	latitude: number;
	longitude: number;
	altitude: number;
	radius: number;
	shape: "circle" | "polygon";
	points: [];
	hidden: boolean;
	locked: boolean;
	siteId: number;
	accountName: string;

	get present() {
		return 1;
	}

	get centerArray() : [] {
		return getCenterArray(this);
	}

	constructor(props) {
		if(props.center) {
			props.latitude = props.center[0];
			props.longitude = props.center[1];
		}
		super(props);
	}

	contains(point) {
		if(!point?.latitude || !point.latitude) return false;
		if(this.get('shape') === 'circle') {
			return isPointWithinRadius(point, this, this.radius);
		}
		const points = this.get('points')
			.map(([latitude, longitude]) => ({latitude, longitude}));
		const {latitude, longitude} = point;
		return isPointInPolygon({latitude, longitude}, points);
	}

	clone() {
		return this.set('id', this.id);
	}

	setShape(shape) {
		return setGeofenceShape(this, shape);
	}
	
	get isSmall() {
		const maxSize = 80;
		if(this.get('shape') === 'polygon') {
			const size = getAreaOfPolygon(this.get('points') || []);
			return size < (Math.PI * maxSize * maxSize);
		}
		return this.get('radius') < maxSize;
	}

	filterString() {
		return this.name + this.accountName;
	}

	static create(lat, lng) {
		return new Geofence({
			id: 0,
			name: '',
			latitude: lat || defaultLat,
			longitude: lng || defaultLng,
			radius: 500,
			shape: 'circle',
			points: [],
			locked: false,
			siteId: null
		});
	}
}
