import Immutable from 'immutable';
import {
	AlarmCenterIcon,
	AlarmIcon,
	AlarmPlusIcon,
	AssistanceRequestIcon, BuildingIcon, DebugIcon,
	EmailIcon,
	GeofenceIcon, IOIcon,
	LocateIcon,
	SendIcon,
	SMSIcon,
	TelegramIcon, TimerIcon,
	VoiceMessageIcon,
	WebhookIcon,
} from '../icons';
import _min from 'lodash/min';
import _max from 'lodash/max';
import SafeguardIcon from '../../components/Icon/SafeguardIcon';
import {isValidPhoneNumber} from "../functions";

export const scenarioActionTypes = {
	showAsAlert: 'showAsAlert',
	osm: 'osm',
	email: 'email',
	voiceMessage: 'voiceMessage',
	sms: 'sms',
	startTracking: 'startTracking',
	safeguardAlarm: 'safeguardAlarm',
	geofencePresence: 'geofencePresence',
	createAlarm: 'createAlarm',
	webhook: 'webhook',
	assistanceRequest: 'assistanceRequest',
	sendTelegramMessage: 'sendTelegramMessage',
	requestAppLogs: 'requestAppLogs',
	sendToARC: 'sendToARC',
	multiTenant: 'multiTenant',
	setDigitalOutput: 'setDigitalOutput',
	resendLastOSM: 'resendLastOSM',
	toggleTimer: 'toggleTimer',
	lowPrioSIA: 'lowPrioSIA'
};
export const notForSimpleViewActions = [
	scenarioActionTypes.multiTenant,
	scenarioActionTypes.assistanceRequest,
	scenarioActionTypes.webhook,
	scenarioActionTypes.geofencePresence
];
export const actionTypeIcons = {
	[scenarioActionTypes.showAsAlert]: AlarmPlusIcon,
	[scenarioActionTypes.osm]: SendIcon,
	[scenarioActionTypes.email]: EmailIcon,
	[scenarioActionTypes.voiceMessage]: VoiceMessageIcon,
	[scenarioActionTypes.sms]: SMSIcon,
	[scenarioActionTypes.safeguardAlarm]: SafeguardIcon,
	[scenarioActionTypes.startTracking]: LocateIcon,
	[scenarioActionTypes.geofencePresence]: GeofenceIcon,
	[scenarioActionTypes.createAlarm]: AlarmIcon,
	[scenarioActionTypes.webhook]: WebhookIcon,
	[scenarioActionTypes.assistanceRequest]: AssistanceRequestIcon,
	[scenarioActionTypes.sendTelegramMessage]: TelegramIcon,
	[scenarioActionTypes.sendToARC]: AlarmCenterIcon,
	[scenarioActionTypes.requestAppLogs]: DebugIcon,
	[scenarioActionTypes.multiTenant]: BuildingIcon,
	[scenarioActionTypes.setDigitalOutput]: IOIcon,
	[scenarioActionTypes.resendLastOSM]: SendIcon,
	[scenarioActionTypes.toggleTimer]: TimerIcon,
	[scenarioActionTypes.lowPrioSIA]: AlarmCenterIcon,
};
export const actionTypeLabels = {
	[scenarioActionTypes.showAsAlert]: 'showInAlerts',
	[scenarioActionTypes.sms]: 'smsMessage',
	[scenarioActionTypes.email]: 'emailMessage',
	[scenarioActionTypes.voiceMessage]: 'voiceMessage',
	[scenarioActionTypes.osm]: 'scenario.osm',
	[scenarioActionTypes.safeguardAlarm]: 'createSafeguardAlarm',
	[scenarioActionTypes.startTracking]: 'startTracking',
	[scenarioActionTypes.geofencePresence]: 'geofencePresence.action',
	[scenarioActionTypes.createAlarm]: 'createAnAlarm',
	[scenarioActionTypes.webhook]: 'callWebhook',
	[scenarioActionTypes.assistanceRequest]: 'sendAssistanceRequest',
	[scenarioActionTypes.sendTelegramMessage]: 'sendTelegramMessage',
	[scenarioActionTypes.sendToARC]: 'sendAlarmToARC',
	[scenarioActionTypes.requestAppLogs]: 'requestLogs',
	[scenarioActionTypes.multiTenant]: 'multiTenant',
	[scenarioActionTypes.setDigitalOutput]: 'setDigitalOutput',
	[scenarioActionTypes.resendLastOSM]: 'resendLastOSM',
	[scenarioActionTypes.toggleTimer]: 'toggleTimer',
	[scenarioActionTypes.lowPrioSIA]: 'sendLowPrioSIA'
};

const ScenarioActionModel = Immutable.Record({
	id: 0,
	type: '',
	config: {}
});

export default class ScenarioAction extends ScenarioActionModel {
	id: number;
	type: string;
	config: any;

	static gpsPlaceholders = ['location', 'geofence_sm', 'geofences', 'beacons', 'beacon', 'gmapsUrl', 'site'];
	
	static containsPositionPlaceholders = (text) => {
		if(!text) return false;
		return !!ScenarioAction.gpsPlaceholders.filter(p => text.indexOf(`{{${p}}}`) >= 0).length;
	};

	get Icon() {
		return actionTypeIcons[this.get('type')];
	}

	get canSave() {
		const conf = this.get('config');
		const validator = require('validator');
		if(this.get('type') === scenarioActionTypes.sendToARC) return true;
		if(this.get('type') === scenarioActionTypes.startTracking) {
			return !!conf.interval && !!conf.duration;
		}
		if(this.get('type') === scenarioActionTypes.email) {
			return conf.recipients && conf.recipients.length >= 1 && conf.text;
		}
		if(this.get('type') === scenarioActionTypes.voiceMessage) {
			if(conf.originator && conf.originator !== 'self' && !isValidPhoneNumber(conf.originator)) return false;
			return conf.recipients && conf.recipients.length >= 1 && conf.text && conf.language;
		}
		if(this.get('type') === scenarioActionTypes.sms) {
			return conf.recipients && conf.recipients.length >= 1 && conf.text;
		}
		if(this.get('type') === scenarioActionTypes.osm) {
			return conf.recipients && conf.text && (conf.recipients.devices.length || conf.recipients.teams.length || conf.recipients.geofences.length);
		}
		if(this.get('type') === scenarioActionTypes.assistanceRequest) {
			return conf.name && conf.recipients && conf.text && (conf.recipients.devices.length || conf.recipients.teams.length || conf.recipients.geofences.length);
		}
		if([scenarioActionTypes.safeguardAlarm, scenarioActionTypes.lowPrioSIA].indexOf(this.get('type')) >= 0) {
			return !!conf.text;
		}
		if(this.get('type') === scenarioActionTypes.sendTelegramMessage) {
			return !!conf.chatId && !!conf.text;
		}
		if(this.get('type') === scenarioActionTypes.geofencePresence) {
			return !!conf.status;
		}
		if(this.get('type') === scenarioActionTypes.setDigitalOutput) {
			return +conf.outputs?.length > 0 && conf.recipients && (conf.recipients.devices.length || conf.recipients.teams.length || conf.recipients.geofences.length);
		}
		if(this.get('type') === scenarioActionTypes.webhook) {
			return !!conf.url && !!conf.method && validator.isURL(conf.url) && conf.url.indexOf('cuebly.com') === -1;
		}
		return [
			scenarioActionTypes.createAlarm,
			scenarioActionTypes.showAsAlert
		].indexOf(this.get('type') >= 0);
	}

	get possiblyTriggersOtherScenarios() {
		return [
			scenarioActionTypes.createAlarm
		].indexOf(this.get('type')) >= 0;
	}
	
	get needsScenario() {
		return !!{
			[scenarioActionTypes.assistanceRequest]: 1
		}[this.get('type')];
	}

	get canTest() {
		return !{
			[scenarioActionTypes.multiTenant]: 1,
			[scenarioActionTypes.startTracking]: 1,
			[scenarioActionTypes.geofencePresence]: 1,
			[scenarioActionTypes.toggleTimer]: 1,
			[scenarioActionTypes.sendToARC]: 1
		}[this.get('type')];
	}

	creditCosts(devices, teams, geofences, beacons, alarmTypeNames) {
		const locations = geofences.toJS().concat(beacons.toJS());
		if(!devices?.toJS()) devices = [];
		let min = 0;
		let max = 0;
		if(this.get('type') === scenarioActionTypes.voiceMessage) {
			min = (this.get('config').recipients || []).length;
			max = (this.get('config').recipients || []).length;
		}
		if(this.get('type') === scenarioActionTypes.sms) {
			let minText = this.get('config').text || '';
			let maxText = this.get('config').text || '';
			if(minText.indexOf('{{device}}') >= 0) {
				minText = minText.split('{{device}}').join(Array(_min(devices.toJS().map(l => l.name.length))).join('-'));
				maxText = maxText.split('{{device}}').join(Array(_max(devices.toJS().map(l => l.name.length))).join('-'));
			}
			if(minText.indexOf('{{beacons}}') >= 0) {
				minText = minText.split('{{beacons}}').join(Array(_min(beacons.toJS().map(l => l.name.length))).join('-'));
				maxText = maxText.split('{{beacons}}').join(Array(_max(beacons.toJS().map(l => l.name.length))).join('-'));
			}
			if(minText.indexOf('{{geofences}}') >= 0) {
				minText = minText.split('{{geofences}}').join(Array(_min(geofences.toJS().map(l => l.name.length))).join('-'));
				maxText = maxText.split('{{geofences}}').join(Array(_max(geofences.toJS().map(l => l.name.length))).join('-'));
			}
			if(minText.indexOf('{{geofence_sm}}') >= 0) {
				minText = minText.split('{{geofence_sm}}').join(Array(_min(geofences.toJS().map(l => l.name.length))).join('-'));
				maxText = maxText.split('{{geofence_sm}}').join(Array(_max(geofences.toJS().map(l => l.name.length))).join('-'));
			}
			if(minText.indexOf('{{location}}') >= 0) {
				minText = minText.split('{{location}}').join(Array(_min(locations.map(l => l.name.length))).join('-'));
				maxText = maxText.split('{{location}}').join(Array(_max(locations.map(l => l.name.length))).join('-'));
			}
			if(minText.indexOf('{{date}}') >= 0) {
				minText = minText.split('{{date}}').join('01-01-2000');
				maxText = maxText.split('{{date}}').join('01-01-2000');
			}
			if(minText.indexOf('{{time}}') >= 0) {
				minText = minText.split('{{time}}').join('10:10');
				maxText = maxText.split('{{time}}').join('10:10');
			}
			if(minText.indexOf('{{alarmType}}') >= 0) {
				minText = minText.split('{{alarmType}}').join(Array(_min(alarmTypeNames.map(a => a.length))).join('-'));
				maxText = maxText.split('{{alarmType}}').join(Array(_max(alarmTypeNames.map(a => a.length))).join('-'));
			}
			if(minText.indexOf('{{gmapsUrl}}') >= 0) {
				minText = minText.split('{{gmapsUrl}}').join(Array(23).join('-'));
				maxText = maxText.split('{{gmapsUrl}}').join(Array(26).join('-'));
			}
			if(minText.indexOf('{{site}}') >= 0) {
				minText = minText.split('{{site}}').join("1234567890");
				maxText = maxText.split('{{site}}').join("1234567890");
			}
			min = Math.ceil(minText.length / this.messageLength);
			max = Math.ceil(maxText.length / this.messageLength);
			min *= (this.get('config').recipients || []).length;
			max *= (this.get('config').recipients || []).length;
		}
		return { min, max };
	}


	get typeName(): string {
		return actionTypeLabels[this.get('type')] || '';
	}

	get messageLength() {
		if(this.get('type') === scenarioActionTypes.safeguardAlarm) return 160;
		if(this.get('type') === scenarioActionTypes.sms) return 160;
		if(this.get('type') === scenarioActionTypes.voiceMessage) return 510;
		return null;
	}

	clone() {
		return this.set('type', this.get('type'));
	}

	constructor(props) {
		super(props);

		const config = this.get('config');
		if(!config.whenEmpty) {
			config.whenEmpty = {};
		}
		ScenarioAction.gpsPlaceholders.forEach(p => {
			if (!config.whenEmpty[p]) {
				config.whenEmpty[p] = '[?]';
			}
		});
		this.set('config', config);
	}

	static create(type, initConfig = {}, language = 'nl-NL') {
		return new ScenarioAction({
			id: 0,
			config: initConfig,
			type,
			language
		});
	}
}
