import { WS_DATA, APP } from './app.constants';

export const ALARMS = `${APP}_alarms`;
export const SHARE_ALARMS = `${ALARMS}/SHARE_ALARMS`;
export const CLOSE_ALARMS = `${ALARMS}/CLOSE_ALARMS`;
export const OPEN_ALARMS = `${ALARMS}/OPEN_ALARMS`;
export const CANCEL_ALARMS = `${ALARMS}/CANCEL_ALARMS`;
export const SET_RECORDING = `${ALARMS}/SET_RECORDING`;
export const GET_SAFETY_REGIONS = `${ALARMS}/GET_SAFETY_REGIONS`;
export const SAFETY_REGIONS_CHANGED = `${ALARMS}/SAFETY_REGIONS_CHANGED`;
export const WS_DATA_ALARM = `${WS_DATA}/alarm`;
export const WS_DATA_ALARMS = `${WS_DATA}/alarms`;
export const WS_DATA_ASS_REQUEST = `${WS_DATA}/assistanceRequest`;
export const WS_DATA_ASS_REQUESTS = `${WS_DATA}/assistanceRequests`;
export const WS_FORCE_RELOAD = `${WS_DATA}/forceReload`;
export const WS_REQUEST_RELOAD_DATA = `${WS_DATA}/requestReloadData`;
export const SET_ALARM_SOUND_ENABLED = `${WS_DATA}/SET_ALARM_SOUND_ENABLED`;
export const SET_ALERT_SOUND_ENABLED = `${WS_DATA}/SET_ALERT_SOUND_ENABLED`;
export const SET_ASS_REQ_SOUND_ENABLED = `${WS_DATA}/SET_ASS_REQ_SOUND_ENABLED`;