import Immutable from 'immutable';
import dayjs from 'dayjs';
import {CANCELED, CLOSED, CLOSED_ARC, CLOSED_EXPIRED, CLOSED_SEQ, colorForState, OPEN} from './AlarmState';
import {AlarmHandler} from './AlarmHandler';
import Device from './Device';
import alarmTypes from './AlarmTypes';
import {eventToTrigger, triggerIcons} from './Scenario';
import {QuestionIcon} from '../icons';

const AlarmModel = Immutable.Record({
    id: 0,
    date: 0,
    cancelable: false,
    checked: 0,
    notes: '',
    arc: null,
    state: 0,
    type: '',
    token: '',
    payload: {},
    snapshot: {},

    accountName: '',
    deviceName: '',
    link: '',

    accountId: 0,
    deviceId: 0,

    disabled: false,
    wizard: {}
});

export default class Alarm extends AlarmModel {
    id: number;
    cancelable: boolean;
    notes: string;
    arc: string;
    state: OPEN | CANCELED | CLOSED | CLOSED_ARC | CLOSED_SEQ | CLOSED_EXPIRED;
    type: string;
    disabled: boolean;
    token: string;
    snapshot: Object;

    deviceName: string;
    accountName: string;

    wizard: Object;

    canClose(device: Device): boolean {
        if (!device) return false;
        if (this.get('cancelable')) return false;
        if (this.get('arc')) return false;
        return this.isOpen && !this.get('disabled');
    }

    get key() {
        return `alarm_${this.id}`;
    }

    get canOpen(): boolean {
        return this.state === CLOSED;
    }

    get checkedDate(): dayjs {
        return dayjs.unix(this.get('checked'));
    }

    get color(): string {
        if (this.get('state') !== CANCELED && this.handledBy)
            return this.handledBy.color;
        return colorForState(this.get('state'));
    }

    get alarmDate(): dayjs {
        return dayjs.unix(this.date);
    }

    get handledBy(): AlarmHandler {
        if (this.state === CLOSED_EXPIRED)
            return AlarmHandler.Platform
        if (this.get('cancelable') || this.get('state') === CANCELED || this.get('state') === CLOSED_SEQ || this.cancelable)
            return AlarmHandler.SequriX;
        return null;
    }

    get handledByText(): string {
        if (this.state === OPEN) {
            return this.handledBy.handledByText;
        } else if (this.state === CANCELED) {
            return 'thisAlarmIsCancelled';
        }
        return this.handledBy.handledByTextPast;
    }

    get isCancelled(): boolean {
        return this.state === CANCELED;
    }

    get isOpen(): boolean {
        return this.state === OPEN;
    }

    get stateName(): string {
        switch (this.get('state')) {
            case OPEN:
                return 'open.alarm';
            case CANCELED:
                return 'cancelled';
            case CLOSED:
            case CLOSED_ARC:
            case CLOSED_SEQ:
                return 'closed';
            case CLOSED_EXPIRED:
                return 'expired';
            default:
                return 'unknownAlarmStatus';
        }
    }

    get typeName(): string {
        switch (this.get('type')) {
            case alarmTypes.SOS:
                return 'sosButton';
            case alarmTypes.MAN_DOWN:
                return 'manDown';
            case alarmTypes.RIPCORD:
                return 'ripcord';
            case alarmTypes.AMBER_SERVER:
                return 'timerNoConnection';
            case alarmTypes.AMBER:
                return 'timer';
            case alarmTypes.GENERIC_ALARM:
                return 'genericAlarm';
            default:
                return 'unknownAlarmType';
        }
    }

    get trigger() {
        return eventToTrigger[this.get('type')];
    }

    get icon() {
        return triggerIcons[this.trigger] || QuestionIcon;
    }
}
