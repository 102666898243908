import { fromJS } from 'immutable';
import {SET_DETAILED_ALARM, TOGGLE_ARC_ALARMS} from './constants';
import { CLOSE_ALARMS, OPEN_ALARMS, SET_RECORDING } from '../../redux/constants/alarm.constants';

export const initialState = fromJS({
	selectedAlarms: [],
	detailedAlarm: null,
	loadingDetailedAlarm: false,
	recording: null,
	showARCAlarms: (localStorage.getItem('showARCAlarms') || 'false') === 'true'
});

function reducer(state: Map = initialState, action) {
	switch (action.type) {
		case SET_DETAILED_ALARM:
			return state
				.set('recording', null)
				.set('detailedAlarm', action.alarm)
				.set('loadingDetailedAlarm', false);
		case TOGGLE_ARC_ALARMS:
			const enabled = !state.get('showARCAlarms');
			localStorage.setItem('showARCAlarms', enabled ? 'true' : 'false');
			return state.set('showARCAlarms', enabled);
		case OPEN_ALARMS:
		case CLOSE_ALARMS:
			if(state.get('detailedAlarm') && action.alarms.find(a => a.id === state.get('detailedAlarm').id)) {
				return state.set('loadingDetailedAlarm', true);
			}
			return state;
		case SET_RECORDING:
			return state.set('recording', action.recording);
		default:
			return state;
	}
}

export default reducer;
