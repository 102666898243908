import {
  CLOSE_ALARMS,
  OPEN_ALARMS,
  SHARE_ALARMS,
  CANCEL_ALARMS,
  WS_DATA_ALARM,
  SET_ALARM_SOUND_ENABLED,
  SET_ALERT_SOUND_ENABLED,
  SET_ASS_REQ_SOUND_ENABLED,
  SET_RECORDING,
  GET_SAFETY_REGIONS,
  SAFETY_REGIONS_CHANGED
} from '../constants/alarm.constants';

export const updateAlarm = (alarm) => ({type: WS_DATA_ALARM, data: alarm });
export const shareAlarms = (alarms, recipients, language) => ({ type: SHARE_ALARMS, alarms, recipients, language});
export const closeAlarms = (alarms, blackbox) => ({ type: CLOSE_ALARMS, alarms, blackbox});
export const openAlarms = (alarms) => ({ type: OPEN_ALARMS, alarms});
export const cancelAlarms = (alarms) => ({ type: CANCEL_ALARMS, alarms });
export const setRecording = (recording) => ({ type: SET_RECORDING, recording });

export const setAlarmSoundEnabled = (isEnabled) => ({ type: SET_ALARM_SOUND_ENABLED, isEnabled });
export const setAlertSoundEnabled = (isEnabled) => ({ type: SET_ALERT_SOUND_ENABLED, isEnabled });
export const setAssistanceRequestSoundEnabled = (isEnabled) => ({ type: SET_ASS_REQ_SOUND_ENABLED, isEnabled });
export const getSafetyRegions = () => ({type: GET_SAFETY_REGIONS});
export const safetyRegionsChanged = data => ({type: SAFETY_REGIONS_CHANGED, data });