import Immutable, {List} from "immutable";
import SubscriptionItem from "./SubscriptionItem";
import dayjs from "dayjs";

const SubscriptionModel = Immutable.Record({
    id: 0,
    customer: '',
    reference: '',
    notes: '',
    start: dayjs().unix(),
    end: null,
    interval: 1,
    items: List(),
    dynamicItems: {},
    taxes_and_charges: '',
    payment_terms_template: ''
});

export default class Subscription extends SubscriptionModel {
    id: number;
    customer: string;
    reference: string;
    start: number;
    end: ?number;
    interval: 1|3|6|12;
    items: SubscriptionItem[];
    dynamicItems: Object;
    taxes_and_charges: string;
    payment_terms_template: string;
    
    get status() {
        if(this.startDate > dayjs()) return 'Toekomstig';
        if(this.endDate && this.endDate < dayjs()) return 'Verlopen';
        return 'Actief';
    }
    get startDate() {
        return dayjs.unix(this.start).startOf('month');
    }
    get endDate() {
        return this.end ? dayjs.unix(this.end).endOf('month') : null;
    }
    get filterString() {
        return JSON.stringify([
            this.get('id'),
            this.get('customer'),
            this.get('reference'),
            this.get('notes'),
            this.get('start'),
            this.get('end')
        ]).lc();
    }

    constructor(props) {
        if(!props) props = {};
        props.dynamicItems = {
            simCards: new SubscriptionItem(props.dynamicItems?.simCards || {item_code: 'SIM cards', item_name: 'SIM cards'}),
            credits: new SubscriptionItem(props.dynamicItems?.credits || {item_code: 'Credits', item_name: 'Credits'}),
            licenses: new SubscriptionItem(props.dynamicItems?.licenses || {item_code: 'Licences', item_name: 'Licences'})
        };
        props.items = List((props.items || []).map(i => new SubscriptionItem(i)));
        super(props);
    }

}