import {compose} from 'redux';
import React from 'react';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import {selectTeams, selectTeamsFetched} from '../../redux/selectors/team.selector';
import {selectGeofences, selectGeofencesFetched, selectPresence} from '../../redux/selectors/geofence.selector';
import {selectDeviceToConnect, selectIsSaving} from './selectors';
import {DialogTitle} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {useTranslation, withTranslation} from 'react-i18next';
import {saveConnections, setDeviceToConnect} from './actions';
import {Flex} from '../../components/Container/Flex';
import {Secondary} from '../../utils/style/texts';
import {moduleIds} from '../../utils/models/Module';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '../../components/Controls/Switch';
import injectSaga from '../../utils/injectSaga';
import {CONNECT_DEVICE_DIALOG} from './constants';
import injectReducer from '../../utils/injectReducer';
import saga from './saga';
import reducer from './reducer';
import TextButton from '../../components/Controls/TextButton';
import SlideUpTransition from '../../components/SlideUpTransition';
import {selectCurrentUser} from '../../redux/selectors/app.selectors';
import Dialog from '../../components/Dialog';
import Alert from "../../components/Alert";

function mapDispatchToProps(dispatch) {
    return {
        close: () => dispatch(setDeviceToConnect(null)),
        save: (d, t, g) => dispatch(saveConnections(d, t, g))
    };
}

const mapStateToProps = createStructuredSelector({
    user: selectCurrentUser(),
    teams: selectTeams(),
    geofences: selectGeofences(),
    device: selectDeviceToConnect(),
    geofencesFetched: selectGeofencesFetched(),
    teamsFetched: selectTeamsFetched(),
    isSaving: selectIsSaving(),
    presence: selectPresence(),
});

const ConnectDeviceDialog = props => {
    const [teamIds, setTeamIds] = React.useState([]);
    const [geofenceIds, setGeofenceIds] = React.useState([]);
    const [t] = useTranslation();

    const {
        teamsFetched,
        geofencesFetched,
        user,
        close,
        teams,
        isSaving,
        geofences,
        save,
        presence
    } = props;
    
    const device = props.device?.toJS() || {};

    const isOpen = !!user && !!teamsFetched && !!geofencesFetched && !!props.device;
    if(isOpen && !geofences.size && !teams.size) {
        setTimeout(close);
        return null;
    }

    return (
        <Dialog
            open={isOpen}
            onError={{
                catch: reset => {
                    close();
                    reset();
                }
            }}
            TransitionComponent={SlideUpTransition}
            TransitionProps={{
                onEntering: () => {
                    setGeofenceIds(Object.keys(presence.byDevice(device.id)).map(i => +i));
                    setTeamIds(teams.filter(t => t.get('deviceIds').indexOf(+device.id) >= 0).map(t => t.id).toJS());
                }
            }}
            fullWidth
            maxWidth="md">
            <DialogTitle>{t('connect.subject', {subject: device.name})}</DialogTitle>
            <DialogContent>
                <Flex>
                    {user?.hasModule && user.hasModule(moduleIds.Teams) && (
                        <Flex col style={{flex: 1}}>
                            <Secondary>{t('teams')}</Secondary>
                            {!teams.size && (
                                <Alert type="info" dismissable={false}>{t('noTeams')}</Alert>
                            )}
                            {teams.map(team => {
                                const index = teamIds.indexOf(+team.id);
                                return (
                                    <FormControlLabel
                                        key={team.id}
                                        value={team.get('name')}
                                        control={(
                                            <Switch
                                                disabled={isSaving}
                                                checked={index >= 0}
                                                onChange={() => {
                                                    const newIds = [...teamIds];
                                                    if (index >= 0) {
                                                        newIds.splice(index, 1);
                                                    } else {
                                                        newIds.push(team.id);
                                                    }
                                                    setTeamIds(newIds);
                                                }}
                                            />
                                        )}
                                        label={team.get('name')}
                                        labelPlacement="end"
                                    />
                                );
                            })}
                        </Flex>
                    )}
                    {user?.hasModule && user.hasModule(moduleIds.Geofences) && (
                        <Flex col style={{flex: 1}}>
                            <Secondary>{t('geofences')}</Secondary>
                            {!geofences.size && (
                                <Alert type="info" dismissable={false}>{t('noGeofences')}</Alert>
                            )}
                            {geofences.map(g => {
                                const index = geofenceIds.indexOf(+g.id);
                                return (
                                    <FormControlLabel
                                        key={g.id}
                                        value={g.get('name')}
                                        control={(
                                            <Switch
                                                disabled={isSaving || g.get('locked')}
                                                checked={index >= 0}
                                                onChange={() => {
                                                    const newIds = [...geofenceIds];
                                                    if (index >= 0) {
                                                        newIds.splice(index, 1);
                                                    } else {
                                                        newIds.push(g.id);
                                                    }
                                                    setGeofenceIds(newIds);
                                                }}
                                            />
                                        )}
                                        label={g.get('name')}
                                        labelPlacement="end"
                                    />
                                );
                            })}
                        </Flex>
                    )}
                </Flex>
            </DialogContent>
            <DialogActions>
                <TextButton disabled={isSaving} onClick={() => close()}>{t('cancel')}</TextButton>
                <TextButton
                    loading={isSaving}
                    disabled={isSaving}
                    onClick={() => save(device, teamIds, geofenceIds)}
                    color="primary">
                    {t('save')}
                </TextButton>
            </DialogActions>
        </Dialog>
    );
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectSaga({key: CONNECT_DEVICE_DIALOG, saga}),
    injectReducer({key: CONNECT_DEVICE_DIALOG, reducer}),
    withTranslation()
)(ConnectDeviceDialog);